<!--
  import Header from '@/components/Header.vue';
 -->
<template>
  <header class="com-flex h center">
    <div class="com-flex h center main">
      <img class="nav" @click="toggleExtend" src="@/assets/images/nav-32-32.svg" alt="">
      <span class="com-flex h center icon">
        <img src="@/assets/images/module-64-64.png" alt="">焰光实验室
      </span>
    </div>
    <ul>
      <li v-for="dx in titleList" :key="dx.uuid">
        <span @click="pushRouter(dx.path)">{{ dx.title }}</span>
      </li>
      <!-- <li>
        <span>登录</span>
        <span class="line">|</span>
        <span>注册</span>
      </li> -->
    </ul>
  </header>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  import { TITLE_LIST } from '@/constants/title.js';

  // Define the component in class-style
  @Component
  export default class ComponentHeader extends Vue {
    extend = false;

    titleList = TITLE_LIST;

    toggleExtend() {
      this.$store.commit('UPDATE_COM_NAV');
    }

    pushRouter(path) {
      console.log(path);

      this.$router.push({
        path,
      });
    }

  }
</script>
<style lang='less' scoped>
  header {
    z-index: 2;
    position: absolute;
    padding: 0 25px;
    width: 100%;
    height: 80px;
    color: #fff;

    &.aux-header {
      z-index: 1;
      background-image: url(~@/assets/images/clog_header_bg.png);
      box-shadow: 0 0 5px rgba(0, 0, 0, .2);
      color: #fff;
    }

    .main {
      flex: 1;
      text-align: left;

      img.nav {
        display: none;
      }
      span.icon {   
        img {
          margin-right: 10px;
          width: 32px;
          border-radius: 6px;
        }
      }
    }
    >ul {
      li {
        float: left;
        padding: 0 15px;

        span.line {
          margin: 0 5px;
        }

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }


  @media only screen and (max-width: 1366px) {
    header {
      height: 60px;
    }
  }
  @media only screen and (max-width: 980px) {
    header {
      padding: 0 10px;
      height: 40px;

      &.aux-header {
        background-color: #1976D2;
        box-shadow: none;
      }

      .main {
        img.nav {
          display: inline-block;
        }
        span.icon {
          display: none;
        }
      }

      >ul {
        display: none;
      }
    }
  }
</style>