export default {
  path: '/user',
  component: () => import(/* webpackChunkName: "User" */ '@/views/User/frame.vue'),
  children: [
    {
      path: '',
      redirect: 'index'
    },
    {
      path: 'index',
      name: 'RouterUserIndex',
      component: () => import(/* webpackChunkName: "UserIndex" */ '@/views/User/index.vue'),
      meta: {
        route: 1
      }
    }
  ]
}