import Vue from 'vue';
import Router from 'vue-router';

//消息
import Other from '@/router/other.js';

Vue.use(Router);

// 兼容异常问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}


const routes = [
  {
    path: '/',
    name: 'RouterIndex',
    component: () => import(/* webpackChunkName: "RouterIndex" */ '@/views/Home/index.vue'),
  },
  {
    path: '/optics',
    component: () => import(/* webpackChunkName: "RouterOptics" */ '@/views/Optics/frame.vue'),
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'RouterOpticsIndex',
        component: () => import(/* webpackChunkName: "'RouterOpticsIndex" */ '@/views/Optics/index.vue'),
      }
    ]
  },
  {
    path: '/meta',
    component: () => import(/* webpackChunkName: "RouterMeta" */ '@/views/Meta/frame.vue'),
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'RouterMetaIndex',
        component: () => import(/* webpackChunkName: "'RouterMetaIndex" */ '@/views/Meta/index.vue'),
      }
    ]
  },
  {
    path: '/visual',
    component: () => import(/* webpackChunkName: "RouterVisual" */ '@/views/Visual/frame.vue'),
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'RouterVisualIndex',
        component: () => import(/* webpackChunkName: "'RouterVisualIndex" */ '@/views/Visual/index.vue'),
      }
    ]
  },
  {
    path: '/spacetime',
    component: () => import(/* webpackChunkName: "RouterSpacetime" */ '@/views/Spacetime/frame.vue'),
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'RouterSpacetimeIndex',
        component: () => import(/* webpackChunkName: "'RouterSpacetimeIndex" */ '@/views/Spacetime/Home/index.vue'),
      },
      {
        path: 'editor',
        name: 'RouterEditorIndex',
        component: () => import(/* webpackChunkName: "'RouterEditorIndex" */ '@/views/Tools/Editor/index.vue'),
      }
    ]
  },
  {
    path: '/scene',
    component: () => import(/* webpackChunkName: "RouterScene" */ '@/views/Scene/frame.vue'),
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'RouterSceneIndex',
        component: () => import(/* webpackChunkName: "'RouterSceneIndex" */ '@/views/Scene/index.vue'),
      }
    ]
  },
  {
    path: '/contact',
    component: () => import(/* webpackChunkName: "RouterContact" */ '@/views/Contact/frame.vue'),
    children: [
      {
        path: '',
        redirect: 'index'
      },
      {
        path: 'index',
        name: 'RouterContactIndex',
        component: () => import(/* webpackChunkName: "'RouterContactIndex" */ '@/views/Contact/index.vue'),
      }
    ]
  },
  {
    path: '/test',
    name: 'RouterTest',
    component: () => import(/* webpackChunkName: "RouterTest" */ '@/views/test.vue'),
  }
];

routes.push(Other);

export default new Router({
  mode: 'history',
  routes: routes
})