import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const Config = {
  modules: {},
  state: {
    COM_NAV: false,
    count: 0,
  },
  mutations: {
    increment (state) {
      state.count++
    },
    UPDATE_COM_NAV(state) {
      state.COM_NAV = !state.COM_NAV;
    }
  }
};

// Config.modules['User'] = User;


export default new Vuex.Store(Config);
