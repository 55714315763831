import Vue from 'vue'
import router from './router';
import store from './store';
import App from './App.vue'
import './registerServiceWorker'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    console.log(`\n\n%c${process.env.VUE_APP_TITLE}`, 'color:#1976D2;font-size:18px;');
    console.log(`%c${process.env.VUE_APP_DESCRIBE}`, 'color:#f00;font-size:14px;', `\n\n`);
  }
}).$mount('#app')
