<template>
  <div id="app" class="com-frame">
    <Header />
    <router-view class="router-view"></router-view>
    <ComponentsNav></ComponentsNav>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';

  import Header from '@/components/Header.vue';
  import ComponentsNav from '@/components/Nav.vue';


  @Component({
    components: {
      Header,
      ComponentsNav
    }
  })
  export default class App extends Vue {
    name = 'App';
  }
</script>

<style scoped>
  @import '~@/assets/style/default.css';
  @import '~@/assets/style/public.css';
</style>
