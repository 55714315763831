<!-- import ComponentsNav from '@/components/Nav.vue'; -->
<template>
  <dl class='components-nav aside' :class="{extend: COM_NAV}">
    <dt @click="toggleExtend"></dt>
    <dd class="com-flex-column">
      <p @click="clickHome" class="com-flex h center"><img src="@/assets/images/module-64-64.png" alt=""> 焰光实验室</p>
      <ul class="com-scroll-y aux-bar">
        <li class="com-flex h center" v-for="dx in titleList" :key="dx.uuid">
          <img :src="dx.icon" alt="">
          <span @click="pushRouter(dx.path)">{{ dx.title }}</span>
        </li>
      </ul>
    </dd>
  </dl>
</template>

<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { mapState } from 'vuex'

  import { TITLE_LIST } from '@/constants/title.js';

  // Define the component in class-style
  @Component({
    computed: {
      ...mapState([
        'COM_NAV'
      ])
    }
  })
  export default class ComponentsNav extends Vue {
    titleList = TITLE_LIST;

    toggleExtend() {
      this.$store.commit('UPDATE_COM_NAV');
    }

    clickHome() {
      this.$router.push({
        path: '/'
      });
    }

    pushRouter(path) {
      console.log(path);

      this.$router.push({
        path,
      });
    }

  }
</script>
<style lang='less' scoped>
  dl.aside {
    z-index: 9;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.extend {
      visibility: visible;

      >dt {
        transition-duration: 500ms;
        opacity: .5;
      }
      >dd {
        transform: translate3d(0, 0, 0);
        transition-duration: 300ms;
      }
    }

    >dt {
      position:absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0);
      opacity: 0;
      transition-duration: 500ms;
    }
    >dd {
      position: absolute;
      height: 100%;
      background-color: #fff;
      transform: translate3d(-100%, 0, 0);
      transition-duration: 300ms;
      text-align: left;
      color: #000;

      /**混合 */
      .MIXINS-image {
        margin-right: 5px;
        width: 25px;
        border-radius: 5px;
      }

      >p {
        padding: 0 25px 0 15px;
        height: 40px;
        line-height: 40px;
        background-color: #1976D2;
        font-weight: bold;
        font-size: 16px;
        color: #fff;

        img {
          .MIXINS-image();
        }
      }

      ul {
        flex: 1;
        padding-bottom: 25px;

        >li {
          padding: 0 25px 0 15px;
          line-height: 40px;
          border-bottom: 1px solid #ddd;
          font-family: 'Microsoft YaHei';

          &:active {
            background-color: #f4f4f4;
          }

          img {
            .MIXINS-image();
          }
        }
      }
    }
  }
</style>